import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ourImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }} className="midnight-blue">
              LET US <span className="purple"> INTRODUCE </span> OURSELVES
            </h1>
            <p className="home-about-body midnight-blue">
              We are passionate about technology and innovation in the field of transportation and infrastructure. At Q-Free Thailand, we specialize in advanced tolling solutions and maintenance services that enhance transportation efficiency and safety.
              <br /><br />
              We are skilled in technologies crucial for our operations, including
              <i>
                <b className="purple"> Network Security, IoT, and Cloud Computing. </b>
              </i>
              <br /><br />
              Our interests include deploying
              <i>
                <b className="purple"> scalable systems </b>
              </i>
              that improve urban mobility and create smart city solutions.
            </p>
          </Col>
          <Col md={4} className="ourAvtar">
            <Tilt>
              <img
                  src={ourImg}
                  className="img-fluid"
                  alt="Stylized avatar of our professional team"
                  style={{ borderRadius: "50%", 
                          maxWidth: "200px", 
                          height: "200px", 
                          overflow: "hidden",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
                        }}
                />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1 className="midnight-blue">FIND US ON</h1>
            <p className="midnight-blue">
              Feel free to <span className="purple">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/qfreeasa/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/q-free/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/qfree_its/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
