import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Particle from "../Particle";
import imgLogoVertical from "../../Assets/logo-vertical.jpg";

function Privacy() {
  return (
    <Container fluid className="privacy-section">
      <Particle />
      <Row className="justify-content-center mb-3">
        <Col md={8} className="text-center">
          <Image 
            src={imgLogoVertical} 
            alt="Q-Free Thailand Logo" 
            className="mb-3" 
            fluid 
            style={{ width: "80px", height: "100px", borderRadius: "18px", paddingTop: "4px"}}
          />
          <h1>Privacy Policy for Q-Free Thailand</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <p>
            <strong>Introduction</strong><br/>
            Welcome to Q-Free Thailand. We are committed to protecting the privacy and security of our users' data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Maintenance app available on Google Play.
          </p>
          <p>
            <strong>Information Collection</strong><br/>
            We collect information that you provide directly to us when you use our services. This includes:
            <ul>
              <li><strong>Personal Information:</strong> Username, password, first name, last name, email address, and mobile phone number.</li>
              <li><strong>Media Files:</strong> Images, videos, and audio recordings that you upload using our app. These files are automatically deleted after 90 days.</li>
            </ul>
          </p>
          <p>
            <strong>Use of Information</strong><br/>
            The information we collect is used to:
            <ul>
              <li>Provide, operate, and maintain our services.</li>
              <li>Process and complete transactions, and send related information, including transaction confirmations and invoices.</li>
              <li>Communicate with you, including via email, social media, and customer support.</li>
              <li>Facilitate repairs and maintenance of hardware and software.</li>
            </ul>
          </p>
          <p>
            <strong>Sharing of Information</strong><br/>
            Your information may be disclosed to:
            <ul>
              <li>Service providers, contractors, and other third parties we use to support our business.</li>
              <li>Affiliates, in which case we will require those affiliates to honor this privacy policy.</li>
              <li>Other users when you share information or interact in the public areas with other users.</li>
            </ul>
          </p>
          <p>
            <strong>Data Security</strong><br/>
            We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
          </p>
          <p>
            <strong>Data Retention</strong><br/>
            We retain your personal information for as long as is necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.
          </p>
          <p>
            <strong>Your Data Protection Rights Under GDPR</strong><br/>
            If you are a resident of the European Union, you have certain data protection rights covered under GDPR. These include:
            <ul>
              <li>The right to access, update or delete the information we have on you.</li>
              <li>The right of rectification.</li>
              <li>The right to object.</li>
              <li>The right of restriction.</li>
              <li>The right to data portability.</li>
              <li>The right to withdraw consent.</li>
            </ul>
          </p>
          <p>
            <strong>Your Rights Under the PDPA</strong><br/>
            If you are a resident of Thailand, you have certain rights under the PDPA. These rights are broadly similar to those under GDPR and include the right to access and receive a copy of your personal data, and the right to request the deletion or anonymization of your personal data.
          </p>
          <p>
            <strong>Changes to This Privacy Policy</strong><br/>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            <strong>Contact Us</strong><br/>
            If you have any questions about this Privacy Policy, please contact us at our office:<br/>
            Q-FREE (Thailand) Co., Ltd.,<br/>
            KPN TOWER, Rama IX Rd, Khwaeng Bang Kapi, Khet Huai Khwang,<br/>
            Bangkok 10320, Thailand
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Privacy;
