import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="midnight-blue" style={{ textAlign: "justify" }}>
            <span className="alizarin">Q-Free</span> is a leading provider of innovative solutions in <span className="purple">smart, safe, and sustainable transportation management</span>. With a strong presence in <span className="purple">Thailand</span>, Q-Free is at the forefront of revolutionizing the transportation sector in the country.
            <br /><br />
            In Thailand, Q-Free leverages its cutting-edge technology and expertise to create intelligent transportation systems that enhance mobility, safety, and environmental sustainability. By integrating advanced sensors, software, and analytics, Q-Free enables efficient traffic flow management, real-time monitoring, and data-driven decision-making for transport authorities and operators.
            <br /><br />
            As a prime mover in the field, Q-Free is committed to shaping the future of transportation in Thailand, making cities more livable, and improving the overall quality of life for residents and visitors alike. Through its innovative solutions, Q-Free is driving positive change, creating smarter, safer, and more sustainable transportation systems for the benefit of all.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
