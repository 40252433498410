import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import lane from "../../Assets/Projects/lane.png";
import lab from "../../Assets/Projects/lab.png";
import toll from "../../Assets/Projects/toll.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          We Recent <strong className="purple">Projects </strong>
        </h1>
        <p className="midnight-blue">
          Here are a few projects We worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lane}
              isBlog={false}
              title="Bangkok, Thailand"
              description="The city of Bangkok is one of the three most polluted cities on the planet. The main reason for the pollution is traffic congestion. Q-Free, has implemented tolling solution to reduce the wait time and improve air quality at tolling gates in the city."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lab}
              isBlog={false}
              title="Bangkok, Thailand"
              description="
              The city of Bangkok is one of the three most polluted cities on the planet. The fine particulate matter PM2.5 index reaches three times the recommended level of safe breathing from the World Health Organization two months a year. The main reason for the pollution is traffic congestion.
              "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={toll}
              isBlog={false}
              title="Bangkok, Thailand"
              description="
              At Q-Free, we have the right solution to reduce the wait time and improve air quality at tolling gates. The implementation of our ETC fast lanes has seen a reduction of the PM2.5 pollutions by a factor of five. Moreover, the wait time at the toll gate plaza was reduced from 15 seconds to 3 seconds.

Q-Free has installed 200 fast lanes citywide, each with a throughput of 1 200 cars per hour – serving a total of 240 000 cars per hour and 5.7 million cars per day. By reducing the wait time from 15 to 3 seconds per traveler a total of 420 000 hours is saved per year.
              "          
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
