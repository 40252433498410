import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function ContactUs() {
  return (
    <Container fluid className="about-section">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <Card className="contact-card">
            <Card.Header as="h5">Contact Us</Card.Header>
            <Card.Body>
              <Card.Title>Q-FREE (Thailand) Co., Ltd.</Card.Title>
              <Card.Text>
                <p><strong>Address:</strong><br />
                719 KPN Tower, 14th Floor Unit B, Rama IX Rd, Khwaeng Bang Kapi, Khet Huai Khwang,<br />
                Bangkok 10310, Thailand</p>
                <p><strong>Phone:</strong><br />
                +66 99 427 4477, +66 94 253 4488</p>
                <p><strong>Email:</strong><br />
                Info.Thailand@q-free.com, support@qfreethailand.com</p>
              </Card.Text>
              <hr />
              <Card.Title>บริษัท คิว-ฟรี (ประเทศไทย) จำกัด</Card.Title>
              <Card.Text>
                <p><strong>ที่ตั้ง:</strong><br />
                719 อาคารเคพีเอ็น, ชั้น 14 ยูนิต บี, ถนนพระราม 9, แขวงบางกะปิ, เขตห้วยขวาง,<br />
                กรุงเทพมหานคร 10310, ประเทศไทย</p>
                <p><strong>โทรศัพท์:</strong><br />
                +66 99 427 4477, +66 94 253 4488</p>
                <p><strong>อีเมล:</strong><br />
                Info.Thailand@q-free.com, support@qfreethailand.com</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
