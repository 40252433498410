import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }} className="midnight-blue">
              About <strong className="alizarin">Q-Free Thailand</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-video"
          >
            <iframe
              src="https://player.vimeo.com/video/398825117?autoplay=1&loop=1&autopause=0"
              style={{ width: "100%", height: "350px" }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Q-Free Thailand Video"
            ></iframe>
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="alizarin">Q-Free</strong><span className="midnight-blue"> at a glance</span>
        </h1>

        <Techstack />
      </Container>
    </Container>
  );
}

export default About;
