import React from "react";
import { Col, Row } from "react-bootstrap";
import foundedINIcon from "../../Assets/glance/founded-in-icon.svg";
import headquarteredIcon from "../../Assets/glance/headquartered-icon.svg";
import officesIcon from "../../Assets/glance/offices-icon.svg";
import employeesIcon from "../../Assets/glance/employees-icon.svg";
import nationalitiesIcon from "../../Assets/glance/nationalities-icon.svg";
import femaleMaleIcon from "../../Assets/glance/femalemale-icon.svg";

function Techstack() {
  return (
    <Row className="midnight-blue" style={{ justifyContent: "center", paddingBottom: "50px", textAlign: "center" }}>
      <Col xs={4} md={2}>
        <img className="tech-icons" src={foundedINIcon} alt="Founded in" />
        <span>FOUNDED IN 1984</span>
      </Col>
      
      <Col xs={4} md={2}>
        <img className="tech-icons" src={headquarteredIcon} alt="Headquartered in" />
        <p>HEADQUARTERED IN<br/>TRONDHEIM, NORWAY</p>
      </Col>

      <Col xs={4} md={2}>
        <img className="tech-icons" src={officesIcon} alt="Offices in" />
        <p>OFFICES IN<br/>15 COUNTRIES</p>
      </Col>

      <Col xs={4} md={2}>
        <img className="tech-icons" src={employeesIcon} alt="Employees" />
        <p>349<br/>EMPLOYEES</p>
      </Col>
      <Col xs={4} md={2}>
        <img className="tech-icons" src={nationalitiesIcon} alt="Nationalities" />
        <p>35<br/>NATIONALITIES</p>
      </Col>
      <Col xs={4} md={2}>
        <img className="tech-icons" src={femaleMaleIcon} alt="Gender Ratio" />
        <p>17% FEMALE<br/>83% MALE</p>
      </Col>
    </Row>
  );
}

export default Techstack;
